import classnames from 'classnames';
import React from 'react';

type Props = {
  percentualProgresso: number;
  title?: string;
  color?: string;
};

const ProgressBar: React.FC<Props> = props => {
  const { percentualProgresso, title, color } = props;

  const inProgress = percentualProgresso < 100;

  const styleBarraDeProgresso = classnames(
    `reach-${percentualProgresso} progressbar-reacher ${color}`,
    {
      striped: inProgress,
      animated: inProgress
    }
  );

  return (
    <>
      <div className="progressbar">
        <div className={styleBarraDeProgresso}></div>
      </div>
      {title && <small className="col-md-12 center form-group">{title}</small>}
    </>
  );
};

export default ProgressBar;
