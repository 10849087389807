import {
  Alert,
  Container,
  FabSpeedDial,
  Icon,
  Info,
  Loading,
  NotificationActions
} from '@elotech/components';
import {
  ContestacaoSectionForm,
  DocumentacaoList,
  DocumentosAvulsosList,
  PessoasQuickView
} from 'itbi-common/components';
import {
  DeclaracaoService,
  ParametroService,
  UploadService,
  withService
} from 'itbi-common/service';
import { FunctionUtils } from 'itbi-common/utils';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import DeclaracaoUtils from '../../utils/DeclaracaoUtils';
import { renderFabButtons } from './DeclaracaoActions';
import ResumoDeclaracao from './Resumo';

const CenteredIcon = styled(Icon)`
  margin: 10% 45%;
`;

class DeclaracaoView extends React.Component {
  static propTypes = {
    declaracaoService: PropTypes.shape({
      findDeclaracaoItbiById: PropTypes.func.isRequired,
      imprimirCertidaoQuitacao: PropTypes.func.isRequired,
      copiarDeclaracao: PropTypes.func.isRequired
    }).isRequired,
    showNotification: PropTypes.func.isRequired
  };
  state = {
    declaracaoItbi: undefined,
    loading: false,
    pessoaQuickView: undefined,
    novo: false,
    documentosAvulsos: [],
    termoAceiteArbitramento: false
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    if (this.props.history.location.state) {
      this.setState({ novo: true });
    }
    this.onGetDeclaracao(id);
    this.getParametroTermoAceiteArbitragem();
  }

  getParametroTermoAceiteArbitragem = () => {
    this.setState({ loading: true });
    this.props.parametroService
      .loadAllParametros()
      .then(response => {
        this.setState({
          termoAceiteArbitramento: response.data.termoAceiteArbitramento
        });
      })
      .catch(error =>
        Alert.error({ title: 'Não foi possível carregar os parâmetros' }, error)
      )
      .finally(() => this.setState({ loading: false }));
  };

  validateVizualizacaoContestacaoDeclaracao = declaracaoItbi => {
    if (!declaracaoItbi.contestacao) {
      this.props.declaracaoService.updateDataVizualizacaoContestacao(
        declaracaoItbi.id
      );
    }
  };

  onGetDeclaracaoSuccess = response => {
    const documentosAvulsos = response.data.documentosAvulsos.filter(
      documentoAvulso => documentoAvulso.visivelCidadao
    );

    this.validateVizualizacaoContestacaoDeclaracao(response.data);

    this.setState({
      declaracaoItbi: response.data,
      documentosAvulsos
    });
  };

  onGetDeclaracaoError = error => {
    Alert.error(
      { title: 'Não foi possível carregar a declaração de ITBI' },
      error
    );
  };

  onGetDeclaracao = id => {
    this.props.declaracaoService
      .findDeclaracaoItbiById(id)
      .then(this.onGetDeclaracaoSuccess)
      .catch(this.onGetDeclaracaoError);
  };

  onPrint = () => {
    window.print();
  };

  onDownloadArquivoContestacao = urlArquivo => {
    const { uploadService } = this.props;
    const { declaracaoItbi } = this.state;

    return uploadService
      .getUrlDownloadArquivoAvulsoDeclaracao(declaracaoItbi.id, urlArquivo)
      .then(urlResult => uploadService.downloadFileS3(urlResult.data.url));
  };

  onDownloadDocumentoAvulso = documento => {
    const { uploadService } = this.props;
    const { declaracaoItbi } = this.state;

    return uploadService
      .getUrlDownloadArquivoAvulsoDeclaracao(declaracaoItbi.id, documento.nome)
      .then(urlResult => uploadService.downloadFileS3(urlResult.data.url));
  };

  onDownloadQuitacao = () => {
    DeclaracaoUtils.downloadCertidaoQuitacao(
      this.props.declaracaoService.imprimirCertidaoQuitacao,
      this.state.declaracaoItbi,
      state => this.setState(state)
    ).then(() => {
      const { id } = this.props.match.params;
      this.onGetDeclaracao(id);
    });
  };

  onCopiar = () => {
    const { id } = this.props.match.params;
    this.props.declaracaoService
      .copiarDeclaracao(id)
      .then(response =>
        this.props.history.push(`/declaracoes-itbi/${response.data.id}`)
      )
      .catch(error =>
        Alert.error(
          { title: 'Não foi possível copiar a declaração de ITBI' },
          error
        )
      );
  };

  onViewPessoa = pessoa => {
    this.setState({
      pessoaQuickView: pessoa
    });
  };

  onCloseQuickViewPessoa = () => {
    this.setState({
      pessoaQuickView: undefined
    });
  };

  onPrintBoleto = () => {
    window.open(this.state.declaracaoItbi.urlBoleto, '_blank');
  };

  onAcceptContestacao = declaracao => {
    this.setState({ loading: true });
    const { termoAceiteArbitramento } = this.state;

    if (termoAceiteArbitramento) {
      this.props.history.push(
        `/declaracoes-itbi/${declaracao.id}/termo-aceite-contestacao`
      );
      return;
    }

    DeclaracaoService.aceitarContestacaoDeclaracao(declaracao.id)
      .then(response => {
        Alert.info({
          title: 'A contestação da declaração de ITBI foi aceita.'
        });
        this.props.history.push(`/declaracoes-itbi/${response.data.id}/resumo`);
      })
      .catch(error =>
        Alert.error(
          {
            title:
              'Não foi possível aceitar a contestação da declaração de ITBI'
          },
          error
        )
      )
      .finally(() => this.setState({ loading: false }));
  };

  onContestContestacao = declaracao => {
    this.props.history.push(`/declaracoes-itbi/${declaracao.id}/contestar`);
  };

  render() {
    const {
      declaracaoItbi,
      loading,
      pessoaQuickView,
      novo,
      documentosAvulsos
    } = this.state;

    if (!declaracaoItbi) {
      return <CenteredIcon size="4x" icon="spinner" spin primary />;
    }

    return (
      <Container title="Resumo da Declaração ITBI" icon="file-signature">
        <Loading loading={loading} />
        {novo ? (
          <Info classes="positive mt-xs no-print">
            {`Declaração gerado com sucesso. O número do pedido é: ${declaracaoItbi.numero}`}
          </Info>
        ) : null}

        <ResumoDeclaracao
          compradores={declaracaoItbi.compradores}
          vendedores={declaracaoItbi.vendedores}
          anuentes={declaracaoItbi.anuentes}
          dadosImovel={declaracaoItbi}
          onViewPessoa={this.onViewPessoa}
        />

        <DocumentacaoList
          documentos={declaracaoItbi.documentos}
          idDeclaracao={declaracaoItbi.id}
        />

        {documentosAvulsos.length > 0 ? (
          <DocumentosAvulsosList
            documentos={documentosAvulsos}
            onDownload={this.onDownloadDocumentoAvulso}
          />
        ) : null}

        <PessoasQuickView
          pessoa={pessoaQuickView}
          onClose={this.onCloseQuickViewPessoa}
        />

        {declaracaoItbi?.contestacao && (
          <ContestacaoSectionForm
            contestacao={declaracaoItbi.contestacao}
            onDownload={this.onDownloadArquivoContestacao}
            isVisibleAllInformations={true}
          />
        )}

        <FabSpeedDial icon="ellipsis-v" title="Ações">
          {renderFabButtons(declaracaoItbi, {
            onPrintBoleto: this.onPrintBoleto,
            onDownloadQuitacao: this.onDownloadQuitacao,
            onCopy: this.onCopiar,
            onPrint: this.onPrint,
            onContest: this.onContestContestacao,
            onAccept: this.onAcceptContestacao
          })}
        </FabSpeedDial>
      </Container>
    );
  }
}

const enhancers = FunctionUtils.compose(
  withService({
    declaracaoService: DeclaracaoService,
    uploadService: UploadService,
    parametroService: ParametroService
  }),
  connect(null, {
    showNotification: NotificationActions.showNotification
  })
);

const enhancedComponent = enhancers(DeclaracaoView);

export { enhancedComponent as default, DeclaracaoView };
