export const somarSemCasasDecimais = (
  num1: number,
  num2: number,
  quantidadeCasasDecimais: number = 2
): number => {
  const val = 10 ** quantidadeCasasDecimais;
  return (num1 * val + num2 * val) / val;
};

export const isNumeric = (value: any): boolean =>
  value - parseFloat(value) + 1 >= 0;
