import {
  Alert,
  Container,
  FAB,
  Loading,
  NotificationActions,
  Panel,
  SearchFilter,
  SearchPagination,
  Table
} from '@elotech/components';
import classnames from 'classnames';
import { renderActionButtons } from 'itbi-common/components/declaracao-itbi/DeclaracaoActions';
import { DeclaracaoService } from 'itbi-common/service';
import DeclaracaoUtils from 'itbi-common/utils/DeclaracaoUtils';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedDate, FormattedNumber } from 'react-intl';
import { connect } from 'react-redux';

import { getFields } from './SearchFieldsDeclaracao';

class DeclaracaoListPage extends React.Component {
  static propTypes = {
    listRequerentes: PropTypes.array.isRequired
  };
  constructor(props) {
    super(props);
    this.buttonRef = React.createRef();
  }
  state = {
    loadingDeclaracoes: false,
    loadingEditar: false,
    showDetailsPrimaryButton: false,
    empty: undefined,
    searchFields: [],
    declaracoes: [],
    pagination: undefined
  };

  componentDidMount() {
    this.setState({ searchParams: '' });
    this.updateSearchFields(this.props.listRequerentes);
  }

  componentDidUpdate(oldProps) {
    if (oldProps.listRequerentes !== this.props.listRequerentes) {
      this.updateSearchFields(this.props.listRequerentes);
    }
  }

  updateSearchFields = listaRequerentes =>
    this.setState({ searchFields: getFields(listaRequerentes) });

  onCreate = () => {
    this.props.history.push('declaracoes-itbi/novo');
  };

  onEditarRascunho = ({ id }) => {
    this.props.history.push(`declaracoes-itbi/${id}`);
  };

  findRequerimentos = (searchParams, page) => {
    this.setState({ searchParams, loadingDeclaracoes: true });
    return DeclaracaoService.loadDeclaracoesByRequerente(searchParams, page)
      .then(response => {
        const {
          content,
          number,
          totalPages,
          first,
          last,
          numberOfElements,
          size
        } = response.data;

        this.setState(state => ({
          loadingDeclaracoes: false,
          declaracoes: content,
          pagination: {
            number,
            totalPages,
            first,
            last,
            numberOfElements,
            size
          },
          empty:
            state.empty === undefined && searchParams === ''
              ? numberOfElements === 0
              : state.empty
        }));

        return response.data;
      })
      .catch(error => {
        this.setState({ loadingDeclaracoes: false });
        Alert.error(
          { title: 'Erro ao fazer a busca de declarações ITBI' },
          error
        );
      });
  };

  searchWithPage = page => {
    this.findRequerimentos(this.state.searchParams, page);
  };

  onPrintDeclaracao = declaracao => {
    DeclaracaoUtils.downloadCertidaoQuitacao(
      DeclaracaoService.imprimirCertidaoQuitacao,
      declaracao,
      state => this.setState(state)
    ).then(() => {
      const { number: page, size } = this.state.pagination;
      this.findRequerimentos(this.state.searchParams, { page, size });
    });
  };

  onViewDeclaracao = declaracao => {
    this.props.history.push(`/declaracoes-itbi/${declaracao.id}/resumo`);
  };

  onAcceptContestacao = declaracao => {
    this.setState({ loadingDeclaracoes: true });
    DeclaracaoService.aceitarContestacaoDeclaracao(declaracao.id)
      .then(response => {
        Alert.info({
          title: 'A contestação da declaração de ITBI foi aceita.'
        });
        this.props.history.push(`/declaracoes-itbi/${response.data.id}/resumo`);
      })
      .catch(error =>
        Alert.error(
          {
            title:
              'Não foi possível aceitar a contestação da declaração de ITBI'
          },
          error
        )
      )
      .finally(() => this.setState({ loadingDeclaracoes: false }));
  };

  onContestContestacao = declaracao => {
    this.props.history.push(`/declaracoes-itbi/${declaracao.id}/contestar`);
  };

  onCopyDeclaracao = declaracao => {
    DeclaracaoService.copiarDeclaracao(declaracao.id)
      .then(response =>
        this.props.history.push(`/declaracoes-itbi/${response.data.id}`)
      )
      .catch(error =>
        Alert.error(
          {
            title: 'Não foi possível copiar a declaração de ITBI'
          },
          error
        )
      );
  };

  onRemoveDeclaracao = async declaracao => {
    const result = await Alert.question({
      title: 'Deseja realmente remover esta declaração?',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      allowOutsideClick: true
    });

    if (result.value) {
      const page = 0;
      const size = this.state.pagination.size;
      DeclaracaoService.remove(declaracao.id)
        .then(() =>
          this.findRequerimentos(this.state.searchParams, { page, size })
        )
        .catch(error =>
          Alert.error(
            {
              title:
                'Não foi possível descartar o rascunho da declaração de ITBI'
            },
            error
          )
        );
    }
  };

  render() {
    const {
      loadingDeclaracoes,
      empty,
      searchParams,
      searchFields,
      declaracoes,
      pagination
    } = this.state;
    const { tipoPessoa } = this.props;

    const actions = {
      onEdit: this.onEditarRascunho,
      onView: this.onViewDeclaracao,
      onDownloadQuitacao: this.onPrintDeclaracao,
      onCopy: this.onCopyDeclaracao,
      onRemove: this.onRemoveDeclaracao,
      onAccept: this.onAcceptContestacao,
      onContest: this.onContestContestacao
    };

    if (empty && !searchParams) {
      this.buttonRef.current && this.buttonRef.current.focus();
    }

    return (
      <Container title="Declarações ITBI" icon="file-signature">
        <Loading loading={loadingDeclaracoes} />
        <Panel isTable>
          {searchFields.length > 0 && (
            <SearchFilter
              fields={searchFields}
              search={this.findRequerimentos}
            />
          )}

          <Table values={declaracoes}>
            <Table.Column
              header="Data Lançamento"
              value={item => (
                <FormattedDate value={item.dataLancamento} timeZone={'UTC'} />
              )}
            />
            <Table.Column
              header="Número do Pedido"
              value={item => item.numero || 'Não gerado'}
            />
            <Table.Column
              header="Valor Transação"
              value={item => (
                <FormattedNumber
                  value={item.valorTransacao}
                  style="currency" // eslint-disable-line
                  currency="BRL"
                  currencyDisplay="symbol"
                />
              )}
            />
            <Table.Column
              header="Valor Financiado"
              value={item => (
                <FormattedNumber
                  value={item.valorFinanciado}
                  style="currency" // eslint-disable-line
                  currency="BRL"
                  currencyDisplay="symbol"
                />
              )}
            />
            <Table.Column
              header="Requerente"
              value={item => item.requerente && item.requerente.nome}
            />
            <Table.Column
              header="Situação"
              value={item => item.situacaoITBI && item.situacaoITBI.descricao}
            />
            <Table.Column
              header=""
              value={item => renderActionButtons(item, actions)}
            />
          </Table>
          {pagination && (
            <SearchPagination
              page={pagination}
              searchWithPage={this.searchWithPage}
            />
          )}
        </Panel>
        {tipoPessoa !== 'JURIDICA' && (
          <div
            className={classnames('btn-save has-details', {
              show: empty
            })}
          >
            <FAB
              icon="plus"
              title="Nova Declaração"
              onClick={this.onCreate}
              ref={this.buttonRef}
              showHintOnFocus
            />
            <div className="details">
              <h1>Novo por aqui?</h1>
              <p className="mt-xs">
                Clique neste botão para solicitar sua primeira <b>declaração</b>
                , é <b> simples </b> e <b>rápido</b>.
              </p>
            </div>
          </div>
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => {
  const listRequerentes =
    (state.user.currentUser && state.user.currentUser.usuariosProcurador) || [];
  return {
    listRequerentes: [state.user.currentUser, ...listRequerentes],
    tipoPessoa: state.user.currentUser.tipoPessoa
  };
};

const ConnectedComponent = connect(mapStateToProps, {
  showNotification: NotificationActions.showNotification
})(DeclaracaoListPage);

export { ConnectedComponent as default, DeclaracaoListPage };
