import { ActionsGroup } from '@elotech/components';
import { PedidoActionButton } from 'itbi-common/components';
import React from 'react';

const onPrintBoletoDefault = urlBoleto => window.open(urlBoleto, '_blank');

const renderEditButton = (requerimento, { onEdit }, props) => (
  <PedidoActionButton
    key="edit-button"
    data-test-id={`edit-button-${requerimento.id}`}
    icon="pencil-alt"
    title="Editar"
    onClick={() => onEdit(requerimento)}
    {...props}
  />
);

const renderViewButton = (requerimento, { onView }, props) =>
  props.fab ? null : (
    <PedidoActionButton
      key="view-button"
      data-test-id={`view-button-${requerimento.id}`}
      icon="eye"
      title="Visualizar"
      onClick={() => onView(requerimento)}
      {...props}
    />
  );

const renderAcceptButton = (requerimento, { onAccept }, props) => (
  <PedidoActionButton
    key="aceitar-button"
    data-test-id={`aceitar-button-${requerimento.id}`}
    icon="check-circle"
    title="Aceitar"
    onClick={() => onAccept(requerimento)}
    {...props}
  />
);

const renderContestButton = (requerimento, { onContest }, props) => (
  <PedidoActionButton
    key="contestar-button"
    data-test-id={`contestar-button-${requerimento.id}`}
    icon="exclamation-circle"
    title="Contestar"
    onClick={() => onContest(requerimento)}
    {...props}
  />
);

const renderPrintBoletoButton = (
  requerimento,
  { onPrintBoleto = onPrintBoletoDefault },
  props
) => {
  if (!requerimento.urlBoleto) {
    return null;
  }
  return (
    <PedidoActionButton
      key="imprimir-boleto-button"
      data-test-id={`print-boleto-button-${requerimento.id}`}
      icon="barcode"
      title="Imprimir Boleto"
      onClick={() => onPrintBoleto(requerimento.urlBoleto)}
      {...props}
    />
  );
};

const renderPrintCertidaoQuitacaoButton = (
  requerimento,
  { onDownloadQuitacao },
  props
) => (
  <PedidoActionButton
    key="print-quitacao-button"
    data-test-id={`print-quitacao-button-${requerimento.id}`}
    icon="file-download"
    title="Imprimir Certidão de Quitação"
    onClick={() => onDownloadQuitacao(requerimento)}
    {...props}
  />
);

const renderPrintButton = (requerimento, actions, props) =>
  props.fab ? (
    <PedidoActionButton
      key="imprimir-button"
      data-test-id={`print-button-${requerimento.id}`}
      icon="print"
      title="Imprimir"
      onClick={() => window.print()}
      {...props}
    />
  ) : null;

const renderCopyButton = (requerimento, { onCopy }, props) => (
  <PedidoActionButton
    key="copy-button"
    data-test-id={`copy-button-${requerimento.id}`}
    icon="copy"
    title="Copiar Para Nova Declaração"
    onClick={() => onCopy(requerimento)}
    {...props}
  />
);

const renderRemoveButton = (requerimento, { onRemove }, props) => (
  <PedidoActionButton
    key="trash-button"
    data-test-id={`delete-button-${requerimento.id}`}
    icon="trash-alt"
    title="Descartar rascunho"
    onClick={() => onRemove(requerimento)}
    {...props}
  />
);

const actionButtons = {
  RASCUNHO: [renderEditButton, renderRemoveButton],
  AGUARDANDO_ANALISE: [renderViewButton, renderPrintButton],
  ANALISANDO: [renderViewButton, renderPrintButton],
  INDEFERIDO: [renderCopyButton, renderViewButton, renderPrintButton],
  DEFERIDO: [renderViewButton, renderPrintButton],
  AGUARDANDO_NOTIFICACAO: [renderViewButton, renderPrintButton],
  AGUARDANDO_GERACAO_BOLETO: [renderViewButton, renderPrintButton],
  AGUARDANDO_PAGAMENTO: [
    renderPrintBoletoButton,
    renderViewButton,
    renderPrintButton
  ],
  TRANSFERENCIA_MANUAL: [
    renderPrintCertidaoQuitacaoButton,
    renderViewButton,
    renderPrintButton
  ],
  TRANSFERIDO: [
    renderPrintCertidaoQuitacaoButton,
    renderViewButton,
    renderPrintButton
  ],
  AGUARDANDO_ACEITE: [
    renderViewButton,
    renderAcceptButton,
    renderContestButton
  ],
  EM_CONTESTACAO: [renderViewButton]
};

export const renderActionButtons = (requerimento, actions, options = {}) => {
  return (
    <ActionsGroup>
      {(actionButtons[requerimento.situacaoITBI.name] || [])
        .map(renderFunction => renderFunction(requerimento, actions, options))
        .filter(item => !!item)}
    </ActionsGroup>
  );
};

export const renderFabButtons = (requerimento, actions) => {
  return (actionButtons[requerimento.situacaoItbi.name] || [])
    .map(renderFunction => renderFunction(requerimento, actions, { fab: true }))
    .filter(item => !!item);
};
