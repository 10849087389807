import {
  Alert,
  Col,
  Container,
  ErrorText,
  FAB,
  FormikTextArea,
  Loading,
  Row,
  SectionTitle,
  Yup,
  useShowNotification
} from '@elotech/components';
import { Formik, FormikProps } from 'formik';
import DocumentosAvulsosList from 'itbi-common/components/documentacao/avulsos/DocumentosAvulsosList';
import { DeclaracaoService, UploadService } from 'itbi-common/service';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';

type Props = {};

const initialValues: any = {
  motivoContestacao: '',
  arquivo: undefined,
  arquivoErrorMessage: undefined,
  nomeArquivo: undefined
};

export const validationSchema = Yup.object().shape({
  motivoContestacao: Yup.string()
    .max(4000)
    .required()
    .label('Motivo Contestação')
});

const DeclaracaoContestacaoFormPage: React.FC<Props> = () => {
  const history = useHistory();
  const showNotification = useShowNotification();
  const [loading, setLoading] = useState(false);
  const [contestacao, setContestacao] = useState(initialValues);
  const [documentos, setDocumentos] = useState<any>([]);

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    DeclaracaoService.findDeclaracaoItbiById(id).then(response => {
      const documentosfiltered = response.data.documentosAvulsos.filter(
        (doc: any) => doc.visivelCidadao && doc.arbitragem
      );

      setDocumentos(documentosfiltered);
    });
  }, [id]);

  const onSaveContestacao = (contestacao: any) => {
    setLoading(true);
    DeclaracaoService.contestarDeclaracao(id, contestacao)
      .then(() => {
        showNotification({
          level: 'success',
          message: `Contestação realizada com sucesso`
        });

        history.replace('/declaracoes-itbi');
      })
      .catch((error: any) => {
        Alert.error({ title: `Erro ao contestar a declaração` }, error);
      })
      .finally(() => setLoading(false));
  };

  const onSubmit = (contestacao: any) => {
    const { arquivo } = contestacao;
    if (!arquivo) {
      onSaveContestacao(contestacao);
      return;
    }

    setLoading(true);
    UploadService.getUrlUploadArquivoAvulsoDeclaracao(id, arquivo[0].name)
      .then(urlResult => {
        return UploadService.uploadFileS3(urlResult.data.url, arquivo[0]);
      })
      .then(() => {
        onSaveContestacao({ ...contestacao, nomeArquivo: arquivo[0].name });
      })
      .catch(error => {
        Alert.error(
          {
            title: 'Erro ao fazer upload do arquivo da contestação'
          },
          error
        );
      })
      .finally(() => setLoading(false));
  };

  const onUploadFile = (event: any, formProps: FormikProps<any>) => {
    const { files } = event.target;

    const isValid = !(files && files[0] && files[0].size > 50000000);

    const errorObj = isValid
      ? { arquivoErrorMessage: undefined }
      : {
          arquivoErrorMessage: 'Arquivo maior que 50Mb'
        };

    setContestacao({
      motivoContestacao: formProps?.values?.motivoContestacao,
      arquivo: files,
      ...errorObj
    });
  };

  const onDownloadDocumentoAvulso = (documento: any) => {
    setLoading(true);

    return UploadService.getUrlDownloadArquivoAvulsoDeclaracao(
      id,
      documento.nome
    )
      .then(urlResult => UploadService.downloadFileS3(urlResult.data.url))
      .finally(() => setLoading(false));
  };

  return (
    <Container title="Contestação Declaração ITBI" icon="paste">
      <Loading loading={loading} />
      <DocumentosAvulsosList
        documentos={documentos}
        customSectionTitle={'Documentos Anexados na Arbitragem'}
        onDownload={onDownloadDocumentoAvulso}
      />
      <SectionTitle>Documento para Contestação</SectionTitle>
      <Formik
        enableReinitialize
        initialValues={contestacao}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        render={(formProps: FormikProps<any>) => (
          <>
            <Row>
              <FormikTextArea
                id="motivoContestacao"
                name="motivoContestacao"
                label="Motivo Contestação"
                size={12}
              />
            </Row>
            <Row>
              <Col sm={6} className="form-group">
                <label htmlFor="upload-file-input">Arquivo</label>
                <div className="file-uploader">
                  <input
                    id="upload-file-input"
                    type="file"
                    className={`file-uploader-input${
                      contestacao?.arquivoErrorMessage ? ' error' : ''
                    }`}
                    title="Clique ou arraste para anexar"
                    onChange={value => onUploadFile(value, formProps)}
                  />

                  <label
                    htmlFor="upload-file-input"
                    className="input"
                    data-title={
                      contestacao?.arquivo && contestacao?.arquivo.length > 0
                        ? contestacao?.arquivo[0].name
                        : 'Clique ou arraste para anexar'
                    }
                  />

                  <label
                    htmlFor="upload-file-input"
                    className="file-uploader-icon"
                  />

                  {contestacao?.arquivoErrorMessage && (
                    <ErrorText>{contestacao?.arquivoErrorMessage}</ErrorText>
                  )}
                </div>
              </Col>
            </Row>
            <div className="btn-save">
              <FAB
                icon="check"
                iconColor="white"
                title="Contestar"
                onClick={formProps.submitForm}
              />
            </div>
          </>
        )}
      />
    </Container>
  );
};

export default DeclaracaoContestacaoFormPage;
