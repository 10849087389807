import 'itbi-common/polyfills';

import './IconsLibrary';

import {
  ActuatorService,
  ApplicationProvider,
  FirebaseNotifications,
  Footer,
  IdentityProviderUtils,
  KeycloakService,
  Loading,
  NotificationContainer,
  Onboarding,
  SidebarToggle,
  UrlUtils,
  getCurrentTheme
} from '@elotech/components';
import axios from 'axios';
import classnames from 'classnames';
import { EntidadeService } from 'itbi-common/service';
import { AxiosUtils } from 'itbi-common/utils';
import PromiseFinallyPolyfill from 'promise.prototype.finally';
import React, { Component } from 'react';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import AppKeycloakProvider from './AppKeycloakProvider';
import keycloakConfig from './keycloak';
import AppHeader from './menus/AppHeader';
import AppSidebar from './menus/AppSidebar';
import Routes from './routes';
import createStore from './state';
import ThemeSelector from './theme/ThemeSelector';

if (!global.Intl) {
  global.Intl = require('intl');
}

if (!Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/polyfill');
  require('@formatjs/intl-pluralrules/dist/locale-data/pt'); // Add locale data for de
}

if (!Intl.RelativeTimeFormat) {
  require('@formatjs/intl-relativetimeformat/polyfill');
  require('@formatjs/intl-relativetimeformat/dist/locale-data/pt'); // Add locale data for de
}
const store = createStore({});
KeycloakService.init(keycloakConfig);

const idpHint = IdentityProviderUtils.getIdpHint();

axios.interceptors.request.use(async config => {
  const { ...newConfig } = config;

  if (
    newConfig.url.startsWith('/api') ||
    newConfig.url.startsWith('/gateway') ||
    newConfig.url.startsWith('/admin') ||
    newConfig.url.startsWith('/painel')
  ) {
    const refreshed = await KeycloakService.refreshToken({ idpHint });
    if (refreshed) {
      newConfig.headers.Authorization = `Bearer ${KeycloakService.getToken()}`;
    }

    if (newConfig.url.startsWith('/gateway')) {
      newConfig.headers.cidade = UrlUtils.getTenantIdentifier();
    }
  }

  AxiosUtils.configureBaseUrl(newConfig);
  return Promise.resolve(newConfig);
});

PromiseFinallyPolyfill.shim();

const notificationService = new FirebaseNotifications('notifications');

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <ThemeProvider theme={getCurrentTheme()}>
          <IntlProvider locale="pt" textComponent="span">
            <NotificationContainer>
              <BrowserRouter basename={process.env.PUBLIC_URL}>
                <ThemeSelector>
                  <AppKeycloakProvider
                    keycloakService={KeycloakService}
                    idpHint={idpHint}
                  >
                    <ApplicationProvider
                      gatewayResource="/gateway"
                      notificationService={notificationService}
                      onGetEntidadePrincipal={EntidadeService.getDadosEntidade}
                      onGetVersaoSobreSistema={() =>
                        ActuatorService.getInfo('/api')
                      }
                    >
                      <SidebarToggle>
                        {({ toggledSidebar, userExists, currentUser }) => {
                          const showMenu =
                            userExists && currentUser?.aceitouUltimoTermo;
                          return (
                            <Onboarding shouldStartTour={!!showMenu}>
                              <div className="wrapper">
                                <AppHeader showMenu={showMenu} />
                                <AppSidebar showMenu={showMenu} />
                                <section
                                  className={classnames({
                                    'main-content': true,
                                    'sidebar-toggle': toggledSidebar,
                                    'no-sidebar': !showMenu,
                                    'sidebar-toggle-md': true
                                  })}
                                >
                                  {userExists === false ||
                                  (userExists && currentUser?.id) ? (
                                    <div>
                                      <Routes
                                        userExists={userExists}
                                        currentUser={currentUser}
                                      />
                                    </div>
                                  ) : (
                                    <Loading loading={true} />
                                  )}
                                </section>
                                <Footer />
                              </div>
                            </Onboarding>
                          );
                        }}
                      </SidebarToggle>
                    </ApplicationProvider>
                  </AppKeycloakProvider>
                </ThemeSelector>
              </BrowserRouter>
            </NotificationContainer>
          </IntlProvider>
        </ThemeProvider>
      </Provider>
    );
  }
}

export default App;
