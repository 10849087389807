import {
  Col,
  DisplayData,
  FormattedCurrency,
  FormattedDate,
  Row,
  SectionTitle,
  formatUtils
} from '@elotech/components';
import React from 'react';

import { porExtenso } from './../../utils/NumeroExtenso';
import DisplayIdentificacaoRequisicao from './identificacao/DisplayIdentificacao';

const RenderDadosTransferencia = ({ dadosTransferencia }) => {
  return (
    <div>
      <SectionTitle>Dados Transferência</SectionTitle>
      <div className="display-data border small mt-xs">
        <Row>
          {dadosTransferencia.numero && (
            <div className={'col-sm-4'}>
              <DisplayData title="Número do Pedido">
                {dadosTransferencia.numero}
              </DisplayData>
            </div>
          )}
        </Row>
        <Row>
          {dadosTransferencia.data && (
            <div className={'col-sm-4'}>
              <DisplayData title="Data">
                <FormattedDate
                  value={dadosTransferencia.data}
                  timeZone={'UTC'}
                />
              </DisplayData>
            </div>
          )}
          <div className={'col-sm-8'}>
            <DisplayData title="Requerente" data-cy="requerente">
              {dadosTransferencia.requerenteNome ||
                (dadosTransferencia.requerente &&
                  dadosTransferencia.requerente.nome)}
            </DisplayData>
          </div>
        </Row>
        <Row>
          <div className={'col-sm-5'}>
            <DisplayData title="Situação">
              {dadosTransferencia.situacaoItbi.descricao}
            </DisplayData>
          </div>
          <div className={'col-sm-7'}>
            <DisplayData title="Tipo de ITBI" data-cy="tipoITBI">
              {dadosTransferencia.tipoItbi.descricao ||
                dadosTransferencia.tipoItbi.label}
            </DisplayData>
          </div>
        </Row>
        <Row>
          <div className={'col-sm-12'}>
            <DisplayData title="Valor Transação" data-cy="valorTransacao">
              <FormattedCurrency value={dadosTransferencia.valorTransacao} />
              {` (${porExtenso(dadosTransferencia.valorTransacao)})`}
            </DisplayData>
          </div>
        </Row>
        <Row>
          {!!dadosTransferencia.valorFinanciado && (
            <div className={'col-sm-12'}>
              <DisplayData
                title={
                  dadosTransferencia.tipoItbi.financiado
                    ? 'Valor Financiado'
                    : 'Valor Anuência'
                }
              >
                <FormattedCurrency value={dadosTransferencia.valorFinanciado} />
                {` (${porExtenso(dadosTransferencia.valorFinanciado)})`}
              </DisplayData>
            </div>
          )}
        </Row>
        <Row>
          <div className={'col-sm-12'}>
            <DisplayData title="Observação">
              {dadosTransferencia.observacaoCidadao}
            </DisplayData>
          </div>
        </Row>
        {dadosTransferencia.motivoIndeferimento && (
          <Row>
            <div className={'col-sm-12'}>
              <DisplayData title="Motivo do indeferimento">
                {dadosTransferencia.motivoIndeferimento}
              </DisplayData>
            </div>
          </Row>
        )}
      </div>
    </div>
  );
};

const DadosCadastroImobiliario = ({ cadastroImobiliario }) => (
  <div>
    <SectionTitle>Dados do Imóvel</SectionTitle>
    <div className="display-data border small mt-xs">
      <div className="row">
        <div className={'col-sm-3'}>
          <DisplayData
            title="Cadastro Imobiliário"
            data-cy="cadastroImobiliario"
          >
            {cadastroImobiliario.cadastro}
          </DisplayData>
        </div>
        <div className={'col-sm-4'}>
          <DisplayData title="Inscrição Cadastral">
            {cadastroImobiliario.inscricaoCadastral}
          </DisplayData>
        </div>
        <div className={'col-sm-2'}>
          <DisplayData title="Quadra">{cadastroImobiliario.quadra}</DisplayData>
        </div>
        <div className={'col-sm-2'}>
          <DisplayData title="Data/Lote">
            {cadastroImobiliario.lote}
          </DisplayData>
        </div>
      </div>
      <div className="row">
        <div className={'col-sm-12'}>
          <DisplayData title="Complemento do Lote">
            {cadastroImobiliario.complementoLote || ''}
          </DisplayData>
        </div>
      </div>
      <div className="row">
        <div className={'col-sm-3'}>
          <DisplayData
            title={'Área Terreno'}
          >{`${cadastroImobiliario.areaTerreno} m²`}</DisplayData>
        </div>
        <div className={'col-sm-3'}>
          <DisplayData
            title={'Área Construida'}
          >{`${cadastroImobiliario.areaConstruida} m²`}</DisplayData>
        </div>
      </div>
      <div className="row">
        <div className={'col-sm-2'}>
          <DisplayData title={'CEP'}>
            {formatUtils.formatCep(cadastroImobiliario.cep)}
          </DisplayData>
        </div>
        <div className={'col-sm-5'}>
          <DisplayData title={'Endereço'}>
            {cadastroImobiliario.endereco}
          </DisplayData>
        </div>
        <div className={'col-sm-2'}>
          <DisplayData title={'Nº'}>{cadastroImobiliario.numero}</DisplayData>
        </div>
        <div className={'col-sm-3'}>
          <DisplayData title={'Complemento'}>
            {cadastroImobiliario.complemento || ''}
          </DisplayData>
        </div>
      </div>
      <div className="row">
        <div className={'col-sm-5'}>
          <DisplayData title={'Bairro'}>
            {cadastroImobiliario.bairro}
          </DisplayData>
        </div>
        <div className={'col-sm-5'}>
          <DisplayData title={'Cidade'}>
            {cadastroImobiliario.cidade}
          </DisplayData>
        </div>
        <div className={'col-sm-2'}>
          <DisplayData title={'Estado'}>
            {cadastroImobiliario.estado}
          </DisplayData>
        </div>
      </div>
      <div className="row">
        <div className={'col-sm-12'}>
          <DisplayData title={'Condomínio'}>
            {cadastroImobiliario.condominio
              ? `${cadastroImobiliario.condominio} -
            ${cadastroImobiliario.descricaoCondominio}`
              : ''}
          </DisplayData>
        </div>
      </div>
    </div>
  </div>
);

const DadosCadastroRural = ({ cadastro }) => (
  <div>
    <SectionTitle>Dados do Cadastro Rural</SectionTitle>
    <div className="display-data border small mt-xs">
      <Row>
        <Col sm={3}>
          <DisplayData title="Cadastro Rural" data-cy="cadastroRural">
            {cadastro.cadastro}
          </DisplayData>
        </Col>
        <Col sm={4}>
          <DisplayData title="Nome">{cadastro.nome}</DisplayData>
        </Col>
        <Col sm={5}>
          <DisplayData title="Endereço Descrito">
            {cadastro.enderecoDescrito}
          </DisplayData>
        </Col>
      </Row>
      <Row>
        <Col sm={4}>
          <DisplayData title="Logradouro">{cadastro.logradouro}</DisplayData>
        </Col>
        <Col sm={2}>
          <DisplayData title="Bairro">{cadastro.bairro}</DisplayData>
        </Col>
        <Col sm={2}>
          <DisplayData title="Complemento">{cadastro.complemento}</DisplayData>
        </Col>
        <Col sm={2}>
          <DisplayData title="Distrito">{cadastro.distrito}</DisplayData>
        </Col>
        <Col sm={2}>
          <DisplayData title="Setor">{cadastro.setor}</DisplayData>
        </Col>
      </Row>
      <Row>
        <Col sm={2}>
          <DisplayData title={'NIRF'}>{cadastro.numeroReceita}</DisplayData>
        </Col>
        <Col sm={2}>
          <DisplayData title={'Número no Incra'}>
            {cadastro.numeroIncra}
          </DisplayData>
        </Col>
        <Col sm={2}>
          <DisplayData title={'Qtd. Alqueires'}>
            {cadastro.alqueires}
          </DisplayData>
        </Col>
        <Col sm={2}>
          <DisplayData title={'Qtd. Hectares'}>{cadastro.hectares}</DisplayData>
        </Col>
        <Col sm={2}>
          <DisplayData title={'Área Total'}>{cadastro.areaTotal}</DisplayData>
        </Col>
      </Row>
    </div>
  </div>
);

const RenderListPessoa = ({ title, list, onViewPessoa, isServidor }) => (
  <div>
    <SectionTitle>{title}</SectionTitle>
    <div className="display-data border small mt-xs has-btn-actions">
      {list.map(pessoa => (
        <DisplayIdentificacaoRequisicao
          key={pessoa.cpfCnpj}
          data-cy={title}
          vendedor={!!pessoa.percentualVenda}
          pessoa={pessoa}
          onViewPessoa={onViewPessoa}
          isServidor={isServidor}
        />
      ))}
    </div>
  </div>
);

const RenderList = ({ title, list = [], onViewPessoa, isServidor }) => {
  if (list.length > 0) {
    return (
      <RenderListPessoa
        title={title}
        list={list}
        onViewPessoa={onViewPessoa}
        isServidor={isServidor}
      />
    );
  }
  return null;
};

const ResumoDeclaracao = ({
  compradores,
  vendedores,
  anuentes,
  dadosImovel,
  onViewPessoa,
  isServidor
}) => (
  <React.Fragment>
    <RenderDadosTransferencia dadosTransferencia={dadosImovel} />
    {dadosImovel.tipoImovel === 'URBANO' ? (
      <DadosCadastroImobiliario
        cadastroImobiliario={dadosImovel.cadastroImobiliario}
      />
    ) : (
      <DadosCadastroRural cadastro={dadosImovel.cadastroRural} />
    )}

    <RenderList
      title="Compradores"
      list={compradores}
      onViewPessoa={onViewPessoa}
      isServidor={isServidor}
    />
    <RenderList
      title="Vendedores"
      list={vendedores}
      onViewPessoa={onViewPessoa}
      isServidor={isServidor}
    />
    <RenderList
      title="Anuentes/Cedentes"
      list={anuentes}
      onViewPessoa={onViewPessoa}
      isServidor={isServidor}
    />
  </React.Fragment>
);

export default ResumoDeclaracao;
