import {
  Col,
  DisplayData,
  FormattedDate,
  Row,
  SectionTitle
} from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const PointerInput = styled.input`
  cursor: pointer;
`;

class ContestacaoSectionForm extends React.Component {
  static propTypes = {
    contestacao: PropTypes.object.isRequired,
    onDownload: PropTypes.func.isRequired,
    isVisibleAllInformations: PropTypes.bool.isRequired
  };

  render() {
    const { contestacao, onDownload, isVisibleAllInformations } = this.props;

    return (
      <React.Fragment>
        <SectionTitle>Dados da Contestação</SectionTitle>
        <div className="display-data border small mt-xs">
          <Row>
            <Col sm={12}>
              <DisplayData title="Motivo Contestação">
                {`${contestacao?.motivo || ''}`}
              </DisplayData>
            </Col>
          </Row>

          {isVisibleAllInformations && (
            <>
              <Row>
                <Col sm={6}>
                  <DisplayData title="Data Contestação">
                    <FormattedDate
                      value={`${contestacao?.dataContestacao || ''}`}
                      timeZone={'UTC'}
                    />
                  </DisplayData>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <DisplayData title="Parecer">
                    {`${contestacao?.parecer || ''}`}
                  </DisplayData>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <DisplayData title="Data Resposta">
                    <FormattedDate
                      value={`${contestacao?.dataResposta || ''}`}
                      timeZone={'UTC'}
                    />
                  </DisplayData>
                </Col>
                <Col sm={6}>
                  <DisplayData title="Situação Contestação">
                    {`${contestacao?.situacao?.descricao || ''}`}
                  </DisplayData>
                </Col>
              </Row>
            </>
          )}
        </div>
        <Row>
          <Col className="form-group" md={6}>
            <label htmlFor="">Arquivo</label>
            <PointerInput
              data-id="urlArquivo"
              name=""
              placeholder={contestacao?.urlArquivo}
              data-title={contestacao?.urlArquivo}
              onClick={() => onDownload(contestacao?.urlArquivo)}
              readOnly
              disabled={!contestacao?.urlArquivo}
            />
            {contestacao?.urlArquivo && (
              <i
                aria-hidden="true"
                onClick={() => onDownload(contestacao?.urlArquivo)}
                className="fa fa-download input-icon"
              />
            )}
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default ContestacaoSectionForm;
