import React from 'react';

import AutoComplete from '../../inputs/AutoComplete';
import { InputDate } from '../../inputs/InputDate';
import { InputDateTime } from '../../inputs/InputDateTime';
import { AutocompleteField, EnumField, Field, Operator } from './Types';

type Props = {
  onEnter(): void;
  field: Field;
  operator?: Operator;
};

type State = {
  selectedValue: any;
};

class SearchInput extends React.Component<Props, State> {
  searchRef = React.createRef<any>();

  state: State = {
    selectedValue: undefined
  };

  componentDidUpdate(oldProps: Props) {
    if (oldProps.field !== this.props.field) {
      this.setState({ selectedValue: undefined });
    }
  }

  getValue = () => {
    return this.props.field.type === 'AUTOCOMPLETE'
      ? this.state.selectedValue
      : this.searchRef.current && this.searchRef.current.value;
  };

  focus = () => this.searchRef.current && this.searchRef.current.focus();

  cleanValue = () => {
    if (this.props.field.type === 'AUTOCOMPLETE') {
      this.searchRef.current.clearValue();
    } else {
      this.searchRef.current.value = '';
    }
  };

  handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      this.props.onEnter();
    }
  };

  onAutocompleteChange = (name: string, newValue: any) =>
    this.setState({ selectedValue: newValue });

  onAutocompleteClear = () => this.setState({ selectedValue: undefined });

  baseStyle = { width: `calc(100% - 82px)`, height: '28px' };

  renderInput = (field: Field, operator: Operator | undefined) => {
    const isContaining =
      operator && ['=CIN=', '=COUT='].includes(operator.symbol);
    const type = field.type === 'NUMBER' && !isContaining ? 'number' : 'text';

    return (
      <input
        autoFocus
        style={this.baseStyle}
        type={type}
        ref={this.searchRef}
        name="searchValue"
        placeholder={
          isContaining
            ? 'Digite o filtro (ex: 1 | 1,2,3 | 10-15 | 1,2,3,10-15)'
            : 'Digite o filtro'
        }
        onKeyDown={this.handleKeyDown}
      />
    );
  };

  renderInputDate = () => (
    <InputDate
      ref={this.searchRef}
      autoFocus
      name="searchValue"
      style={this.baseStyle}
      onKeyDown={this.handleKeyDown}
    />
  );

  renderInputDateTime = () => (
    <InputDateTime
      ref={this.searchRef}
      autoFocus
      name="searchValue"
      style={this.baseStyle}
      onKeyDown={this.handleKeyDown}
    />
  );

  renderSelectEnum = (field: EnumField) => {
    return (
      <select
        autoFocus
        style={this.baseStyle}
        ref={this.searchRef}
        name="searchValue"
      >
        {field.options.map(option => (
          <option key={option.name} value={option.name}>
            {option.descricao}
          </option>
        ))}
      </select>
    );
  };

  renderAutoComplete = (field: AutocompleteField) => {
    return (
      <div style={this.baseStyle}>
        <AutoComplete
          key={field.name}
          ref={this.searchRef}
          name="searchValue"
          value={this.state.selectedValue}
          onSearch={field.loadOptions}
          onSelect={this.onAutocompleteChange}
          getOptionValue={field.getOptionValue}
          getOptionLabel={field.getOptionLabel}
          onClear={this.onAutocompleteClear}
        />
      </div>
    );
  };

  renderSelectBoolean = () => {
    return (
      <select
        autoFocus
        style={this.baseStyle}
        ref={this.searchRef}
        name="searchValue"
      >
        <option key="sim" value={'true'}>
          Sim
        </option>
        <option key="nao" value={'false'}>
          Não
        </option>
      </select>
    );
  };

  render() {
    const { field, operator } = this.props;
    switch (field.type) {
      case 'ENUM':
        return this.renderSelectEnum(field);
      case 'AUTOCOMPLETE':
        return this.renderAutoComplete(field);
      case 'BOOLEAN':
        return this.renderSelectBoolean();
      case 'DATE':
        return this.renderInputDate();
      case 'DATE_TRUNC':
        return this.renderInputDate();
      case 'DATETIME':
        return this.renderInputDateTime();

      default:
        return this.renderInput(field, operator);
    }
  }
}

export default SearchInput;
